import { Controller } from './controller';
import { LdapProfileModal } from '../components/ldapProfileModal';
import { SchedulerTaskModal } from '../components/schedulerTaskModal';
import { NotificationModal } from '../components/notificationModal';
import { VariableModal } from '../components/variableModal';
import { UserModal } from '../components/userModal';
import { UserPolicyModal } from '../components/userPolicyModal';
import { AuthSettingsModal } from '../components/userAuthSettingsModal';
import { MailServerModal } from '../components/mailServerModal';
import { MailServerDetailsModal } from '../components/mailServerDetailsModal';
import { CoursesModal } from '../components/coursesModal';
import { CourseModal } from '../components/courseModal';
import { BossKadrovikProfileModal } from '../components/bossKadrovikModal';
import { AUTH_SERVICE, BOSS_KADROVIK_SERVICE, COURSES_SERVICE, DEFAULT_PER_PAGE_SIZE, LDAP_PROFILES_SERVICE, LEARNING_SERVICE, MAIL_SERVERS_SERVICE, NOTIFICATIONS_SERVICE, ROLES_SERVICE, SCHEDULER_TASKS_SERVICE, SETTINGS_SERVICE, TIME_ZONES_SERVICE, USERS_SERVICE, VARIABLES_SERVICE } from '../const';
import angular from 'angular';
import moment from 'moment';
import scroller from '../helpers/scroller.js';
import { hasAccess, getAuthType } from '../reselect';
import setOptions from '../helpers/setOptions';
import { TwoFactorDisableModal, TwoFactorPassModal, TwoFactorQRModal } from '../components/twoFactorAuthModal';
import { setDaemonsMessage } from '../helpers/setDaemons';
import { setAuthProps, setLinkings, setOneTimeMessage, setCourses, setTemplates, clearSelectedData, setDefaultNotification, setNotification, setReportTypes, setActionTypes, setTriggerTypes, setListWifiAttack } from '../toolkit/actions';
import { fetchCerts, fetchSSO, fetchDashboardParams } from '../toolkit/think';
import reactSettings from 'rootReact/containers/Settings';
import ConfirmPopup from 'rootReact/components/ConfirmPopup';

const Actions = {
    setAuthProps,
    setLinkings,
    setOneTimeMessage,
    clearSelectedData,
    setDefaultNotification,
    setNotification,
    setReportTypes,
    setActionTypes,
    setTriggerTypes,
    fetchCerts,
    fetchSSO,
    setTemplates,
    setCourses,
    fetchDashboardParams,
    setListWifiAttack
};

class SettingsController extends Controller {
    get linkModeOneTime() { return 1; } // обучение - ссылка одноразовая
    get linkModeUnlimited() { return 2; } // обучение - ссылка бессрочная

    constructor(
        $injector, $scope, $localStorage, $timeout, FileUploader, FileReader, AuthService,
        MailServersService, SettingsService, LearningService, LdapProfilesService,
        SchedulerTasksService, NotificationsService, VariablesService,
        UsersService, TimeZonesService, CoursesService, RolesService, $ngRedux, $location, $anchorScroll, BossKadrovikProfilesService) {
        super();
        this.config = window.config;
        this.anchorScroll = $anchorScroll;
        this.$injector = $injector;
        this.$location = $location;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.$timeout = $timeout;
        this.FileReader = FileReader;
        this.ConfirmPopup = ConfirmPopup;
        this.reactSettings = reactSettings;
        this.logoUploader = new FileUploader();
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.logoUploader.onCompleteItem = (item, response, status) => {
            this.inProcess.logo = null;
            if (status == 200) {
                this.logoIsUpdated = true;
                this.logoUploader.queue = [];
                this.learningConfig.systemLogo = response.systemLogo;
                this.learningConfig.systemLogoExt = response.systemLogoExt;
            }
        };
        this.logoUploader.onErrorItem = (item, response) => {
            this.inProcess.logo = null;
            if (response && response.error) {
                this.errors.logo = response.error;
            } else {
                this.errors.logo = this.gettextCatalog.getString('Ошибка при загрузке логотипа');
            }
        };
        this.isOpenCalend = false;
        this.downloadFromDate = moment(Date.now() - 1000*60*60*24*7).format('YYYY-MM-DD HH:mm:ss');
        this.downloadToDate = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
        this.fromDateOptions = {};
        this.period = false;
        this.toDateOptions = {};
        this.AuthService = AuthService;
        this.MailServersService = MailServersService;
        this.SettingsService = SettingsService;
        this.LearningService = LearningService;
        this.LdapProfilesService = LdapProfilesService;
        this.BossKadrovikProfilesService = BossKadrovikProfilesService;
        this.CoursesService = CoursesService;
        this.SchedulerTasksService = SchedulerTasksService;
        this.NotificationsService = NotificationsService;
        this.UsersService = UsersService;
        this.RolesService = RolesService;
        this.departments = [];
        this.ldapProfiles = [];
        this.hasNewLearningParams = false;
        if ($localStorage.userInfo.targetImport && $localStorage.userInfo.targetImport.bossKadrovikEnabled) {
            this.bossKadrovikProfiles = [];
        }
        this.courses = [];
        this.schedulerTasks = [];
        this.notifications = [];
        this.wifiAttak = [];
        this.variables = [];
        this.users = [];
        this.roles = [];
        this.ssoTableHeader = [
            {
                name: 'asd',
                handleClick: () => {console.log(123);}
            },
            {
                name: 'ddds',
                handleClick: () => null
            }
        ];
        /** TODO: Убрать методы перезагрузки списков **/
        this.courseList = CoursesService.all;
        this.schedulerTaskList = SchedulerTasksService.all;
        this.notificationList = NotificationsService.all;
        this.variableList = VariablesService.all;
        this.userList = UsersService.all;
        this.roleList = RolesService.all;
        this.RolesService = RolesService;
        this.ldapProfileList = LdapProfilesService.all;
        if ($localStorage.userInfo.targetImport && $localStorage.userInfo.targetImport.bossKadrovikEnabled) {
            this.bossKadrovikProfileList = BossKadrovikProfilesService.all;
        }
        this.mailServerList = MailServersService.all;
        this.timeZones = [];
        this.loading = {};
        this.inProcess = {
            profile: false,
        };
        this.errors = {};
        this.form = {};
        this.modal = {};
        this.cacheCourseById = [];
        this.cacheTemplateById = [];
        this.cacheSchedulerTriggerTypeById = {};
        this.cacheSchedulerActionTypeById = {};
        this.userInfo = {
            timeZone: 'UTC',
            timeZoneGmt: '+0',
        };
        this.loading.userInfo = false;
        this.domains = [];
        this.domainsVisible = 5;
        this.lastTimeZone = null;
        this.lastDepartment = null;
        this.reactLoad = false;
        this.certCount = 0;
        this.lastProfileData = null;
        this.lastTimeZoneGmt = '+0';
        this.showPasswordForm = false;
        this.enabledSavePolicy = true;
        this.days_as_new = $localStorage.userInfo.daysAsNew;
        this.disabled = {
            'policy': {
                'length': false,
                'digit_min': false,
                'char_small': true,
                'char_big': true,
                'char_special': true,
                'digit': true,
            },
        };
        this.lastLearningConfig = {
            location: 0,
            type: 2,
        };
        this.mailServers = [];
        this.appCtrl = window.appCtrl;
        this.learningConfigLoaded = false;
        this.languages = [];
        angular.forEach(this.appCtrl.languages, (langName, langCode) => {
            this.languages.push({
                code: langCode,
                label: langName,
            });
        });
        this.debugArchiveEnabled = false;
        this.zipClassExists = true;
        this.systemUpdateEnabled = false;
        this.saveDebugLink = this.gettextCatalog.getString('Сохранить отладочную информацию');
        this.saveDebugLoading = this.gettextCatalog.getString('Подготовка отладочной информации');
        this.saveDebugError = this.gettextCatalog.getString('Ошибка при подготовке отладочной информации');
        this.manifestLink = '';
        this.certCount = 0;
        this.noneCetificate = false;
        this.noneCetificateMessage = this.gettextCatalog.getString('Нет сертификатов для скачивания');
        window.settingsCtrl = this;
        this.anonymization = this.isAnonymization;
        this.noneCetificate = false;
        this.prepareCertMessage = this.gettextCatalog.getString('Готовим архив сертификатов...');
        this.noneCetificateMessage = this.gettextCatalog.getString('Нет сертификатов для скачивания');
        this.linkTextCerts = this.gettextCatalog.getString('Скачать сертификаты сотрудников архивом');
        this.strings = {
            eduLogLink: this.gettextCatalog.getString('журнал обучения'),
            actionLogLink: this.gettextCatalog.getString('журнал действий'),
            courseNotFound: this.gettextCatalog.getString('Курс не найден или больше не доступен'),
            unknownAction: this.gettextCatalog.getString('Неизвестное действие'),
            templateNotFound: this.gettextCatalog.getString('Шаблон не найден или больше не доступен'),
            templateRandom: this.gettextCatalog.getString('Случайный шаблон'),
            ldapLogLink: this.gettextCatalog.getString('журнал синхронизаций')
        };
        this.ngRedux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, Actions)(this);
    }

    mapStateToThis = (state) => {
        return {
            hashPath: state.linking.link,
            viewAccess: hasAccess(state, { sectionId: 5, rightName: 'view' }),
            anonymization: state.auth.auth.license.features.anonymization,
            manageSSO: state.auth.auth.license.features.manageSso,
            authType: state.auth.auth.authType,
            isSuperAdmin: state.auth.auth.adminId === 0,
            outlookPluginSettingsAccess: hasAccess(state, { sectionId: 5, rightName: 'settings_plugin_email_clients' }),
            ssoProfiles: state.ssoProfile.ssoProfiles,
            isSSO: getAuthType(state) === 3,
            downloadAllCertsAccess: hasAccess(state, { sectionId: 6, rightName: 'user_download_all_certs' }),
            ldapAccsess: hasAccess(state, { sectionId: 1, rightName: 'target_ldap' }),
            instructionCustomFileName: state.auth.auth.plugin.installManualFilename,
            twoFactorEnable: state.auth.auth.usesSecondFactor,
            instructionLink: state.auth.auth.plugin.installManualLink,
            certsMessage: state.notification.certs,
            certReduce: state.certificate,
            courses: state.courses.courses,
            learningConfig: state.settings.settings.learning,
            educationEditable: hasAccess(state, { sectionId: 6, rightName: 'education_save' }),
            certDownload: hasAccess(state, { sectionId: 6, rightName: 'user_download_all_certs' }),
            reportEducation: hasAccess(state, { sectionId: 8, rightName: 'report_education' }),
            notificationsView: hasAccess(state, { sectionId: 7, rightName: 'view' })
        };
    };

    toggleAnonymize = () => {
        this.settingsLoad = true;
        let options = {};
        options.headers = {};
        options.headers['X-Csrf-Token'] = window.csrfToken;
        options.headers['Enable-Session'] = 1;
        fetch('v2/settings/toggle-anonymization', options).then(() => {
            location.reload();
        });
        this.certCount += 1;
        window.EducationCtrl.hidden = false;
    }

    $onInit() {
        const { $localStorage } = this;
        if (!this.viewAccess) {
            this.appCtrl.go($localStorage.userInfo.defaultPage || 'targets');
            return;
        }
        this.fetchCerts();
        this.fetchSSO();
        this.fetchSettings();
        this.clearSelectedData();
    }

    toggleAnonymize = () => {
        this.settingsLoad = true;
        let options = {};
        options.headers = {};
        options.headers['X-Csrf-Token'] = window.csrfToken;
        options.headers['Enable-Session'] = 1;
        fetch('v2/settings/toggle-anonymization', options).then(() => {
            location.reload();
        });
    }

    closeCertDownload = (target) => {
        if (!this.isOpenCalend) return;
        if (target === document.body) {
            this.isOpenCalend = false;
            this.$scope.$apply();
            return;
        }
        if (target.classList.contains('cert-dowloader-panel') || target.classList.contains('cert-dowloader-link')) {
            return;
        }
        if (target.parentNode) {
            this.closeCertDownload(target.parentNode);
            return;
        }
    }

    fetchSettings = () => {
        this.settingsLoad = true;
        let options = {};
        options.headers = {};
        options.headers['X-Csrf-Token'] = window.csrfToken;
        options.headers['Enable-Session'] = 1;
        fetch(window.config.SYSTEM_URL + window.config.API_URL + '/settings', options).then(responese => {
            return responese.json();
        }).then(settings => {
            this.debugArchiveEnabled = settings.debugArchiveEnabled || false;
            this.zipClassExists = settings.zipClassExists || true;

            this.timeZones = settings.timeZones || [];
            this.departments = settings.departments || [];
            this.validUserInfo(settings.auth);
            // this.downloadFromDate = settings.auth.createdAt;
            this.users = settings.users || [];
            this.roles = settings.roles || [];
            this.schedulerTasks = settings.schedulerTasks || [];
            this.schedulerReportTypes = settings.reportTypes || [];
            this.setReportTypes(settings.reportTypes || []);
            if (!this.notificationsView) {
                settings.actionTypes = settings.actionTypes?.filter(item => item.id !== 'target_notify');
            }
            this.schedulerActionTypes = settings.actionTypes || [];
            this.setActionTypes(settings.actionTypes || []);
            this.schedulerTriggerTypes = settings.triggerTypes || [];
            this.setTriggerTypes(settings.triggerTypes || []);
            this.templates = settings.templates || [];
            this.setTemplates(this.templates);
            this.ldapProfiles = settings.ldapProfiles || [];
            this.showPasswordForm = settings.ldapProfileId === null;
            this.variables = settings.variables || [];
            this.notifications = settings.notifications || [];
            this.wifiAttak = settings.wifiAttak || [];
            this.mailServers = settings.mailServers;
            this.bossKadrovikProfiles = settings.bossKadrovikProfiles || [];
            this.learningConfig = settings.learning || {};
            this.lastLearningConfig = angular.copy(this.learningConfig);
            this.systemUpdateEnabled = settings.systemUpdateEnabled || false;
            this.manifestLink = settings.manifestLink || '';
            this.pluginR7Link = settings.pluginR7Link || '';
            this.licenseCompanyTargetCount = settings.companyTargetCount  || settings.auth.companyTargetCount;
            if (settings.auth.license) {
                if (settings.auth.license.domain) {
                    this.domains = settings.auth.license.domain.split(', ');
                }
            }
            this.recalcInactiveNotifications();
        }).then(() => {
            this.settingsLoad = false;
            this.learningConfigLoaded = true;
            this.$scope.$apply();
            this.$timeout(this.setWatcher(this.hashPath), 10);
        });
    };

    validUserInfo = (userInfo) => {
        if (typeof (userInfo.department) == 'undefined') {
            userInfo.department = {};
        }
        if (userInfo.license) {
            if (userInfo.license.domain) {
                this.domains = userInfo.license.domain.split(', ');
            }
            if (userInfo.license.validTill) {
                userInfo.license.validTill = moment(
                    userInfo.license.validTill, 'YYYY-MM-DD HH:mm:ss').toDate();
            }
        }
        this.lastProfileData = {
            'position': userInfo.position || '',
            'timeZone': userInfo.timeZone || '',
        };
        if (userInfo.department) {
            if (userInfo.department.name) {
                this.lastDepartment = userInfo.dzipClassExistsepartment;
            }
            if (userInfo.department.id > 0) {
                this.lastProfileData['departmentId'] = userInfo.department.id;
            }
        }
        this.lastTimeZone = userInfo.timeZone ? userInfo.timeZone : null;
        if (this.lastTimeZone) {
            let timeZone = this.timeZones.find((timeZone) => {
                return this.lastTimeZone == timeZone.name;
            });
            if (timeZone && timeZone.name) {
                userInfo.timeZoneGmt = timeZone.gmt;
                this.lastTimeZoneGmt = timeZone.gmt;
            }
        } else {
            userInfo.timeZoneGmt = '+0';
            this.lastTimeZoneGmt = '+0';
        }
        this.userInfo = userInfo;
    };

    setPeriod = () => {
        this.period = !this.period;
    }

    isLoading = (dataType) => {
        if (this.settingsLoad) {
            return true;
        }
        if (!dataType) {
            return this.loading.settings || this.loading.userInfo;
        } else if (dataType == 101) {
            return this.isLoading() || this.loading.users;
        } else if (dataType == 102) {
            return this.isLoading(101) || this.loading.roles;
        } else if (dataType == 8) {
            return this.isLoading(102) || this.loading.courses;
        } else if (dataType == 9) {
            return this.isLoading(8) || this.loading.scheduler;
        } else if (dataType == 10) {
            return this.isLoading(9) || this.loading.ldap;
        } else if (dataType == 11) {
            return this.isLoading(10) || this.loading.variables;
        } else if (dataType == 12) {
            return this.isLoading(11) || this.loading.mailServers;
        } else if (dataType == 13) {
            return this.isLoading(12) || this.loading.bossKadrovik;
        }
    };

    setWatcher = (hashPath) => {
        let setWatcher = (value) => {
            if (!value || value.search(/attacksStart/g) !== -1 || value.search(/sendTraining/g) !== -1) {
                return;
            }
            if (this.hashValues?.includes(value)) {
                return;
            }
            if (value === 'accountCreate') {
                this.focusedOn(value, this.saveUser);
                return;
            }
            if (value === 'ldapSaveField') {
                this.focusedOn('ldapSaveField', this.saveLdapProfile);
            }
            // if (value === 'scheduler') {
            //     this.focusedOn('scheduler', this.saveSchedulerTask);
            // }
            if (value === 'passwordField') {
                this.focusedOn('passwordField', this.openPolicy);
            }
            if (value === 'roleSaveField') {
                this.focusedOn('roleSaveField', this.saveRole);
            }
            this.focusedOn(value);
        };
        setWatcher(hashPath);
        this.$scope.$watch(scope => {
            return scope.settingsCtrl.hashPath;
        }, (newValue, oldValue) => {
            if (newValue === oldValue || newValue === '') {
                return;
            }
            setWatcher(newValue);
        });
    };

    setForm = (type, form) => {
        this.form[type] = form;
    };

    onDepartmentSelect = ($item, $model, $label) => {
        if (typeof (this.userInfo.department) == 'undefined') {
            this.userInfo.department = {};
        }
        this.userInfo.department.name = $label;
        this.saveProfile();
    };

    onClickCreateTarget = () => {
        this.saveProfile(true);
    };

    updateDaysAsNew = () => {
        this.errors.days_as_new = null;
        this.form.daysAsNew.$setSubmitted();
        if (this.form.daysAsNew.$valid) {
            let data = {
                'days_as_new': this.days_as_new,
            };
            this.inProcess.days_as_new = true;
            this.SettingsService.changeDaysAsNew(data, this, (ctrl) => {
                ctrl.inProcess.days_as_new = null;
                ctrl.days_as_new_changed = true;
                ctrl.$localStorage.userInfo.daysAsNew = ctrl.days_as_new;
            }, (ctrl, response) => {
                if (response && response.data && response.data.error) {
                    if (response.data.field == 'days_as_new') {
                        ctrl.errors.days_as_new_changed = response.data.error;
                    }
                }
                ctrl.inProcess.days_as_new = null;
            });
        }
    };

    savePassword = () => {
        this.errors.password = null;
        this.errors.oldPassword = null;
        this.form.password.$setSubmitted();
        if (this.form.password.$valid) {
            let data = {
                'old_password': this.oldPassword,
                'password': this.newPassword,
            };
            this.inProcess.password = true;
            this.SettingsService.changeProfilePassword(data, this, (ctrl) => {
                ctrl.inProcess.password = null;
                ctrl.oldPassword = null;
                ctrl.newPassword = null;
                ctrl.passwordChanged = true;
            }, (ctrl, response) => {
                if (response && response.data && response.data.error) {
                    if (response.data.field == 'old_password') {
                        ctrl.errors.oldPassword = response.data.error;
                    }
                    if (response.data.field == 'password') {
                        ctrl.errors.password = response.data.error;
                    }
                }
                ctrl.inProcess.password = null;
            });
        }
    };

    saveProfile = (createTarget) => {
        if (!this.inProcess.profile) {
            const { $localStorage } = this;
            this.targetId = null;
            this.errors.target = null;
            this.errors.department = null;
            this.errors.target = null;
            if (createTarget) {
                if (!this.userInfo.department || !this.userInfo.department.name) {
                    this.errors.department = true;
                }
            }
            let profileData = {
                'position': this.userInfo.position || '',
                'timeZone': this.userInfo.timeZone || '',
            };
            if (this.userInfo.department && this.userInfo.department.id > 0) {
                profileData['departmentId'] = this.userInfo.department.id;
            }
            let department = null;
            if (typeof (this.userInfo.department) == 'string') {
                department = this.departments.find((item) => {
                    return item.fullName == this.userInfo.department;
                });
                if (department && department.id) {
                    profileData['departmentId'] = department.id;
                } else {
                    profileData['departmentName'] = this.userInfo.department;
                }
            }
            if (createTarget) {
                profileData['createTarget'] = 1;
            }
            if (this.lastProfileData.position != profileData.position ||
                this.lastProfileData.timeZone != profileData.timeZone ||
                this.lastProfileData.departmentId != profileData.departmentId ||
                this.lastProfileData.departmentName != profileData.departmentName ||
                createTarget) {
                this.inProcess.profile = true;
                if (createTarget) {
                    this.inProcess.target = true;
                }
                $localStorage.userInfo = angular.extend($localStorage.userInfo, {
                    timeZone: profileData.timeZone,
                });
                this.lastProfileData = profileData;
                this.SettingsService.saveProfileInfo(profileData, this,
                    (ctrl, response) => {
                        if (this.inProcess.target == true) {
                            this.targetId = response.targetId;
                            this.fetchDashboardParams();
                        }
                        ctrl.inProcess.target = null;
                        ctrl.inProcess.profile = null;
                    }, (ctrl, response) => {
                        if (response && response.data &&
                            response.data.error) {
                            ctrl.errors.target = response.data.error;
                        }
                        ctrl.inProcess.target = null;
                        ctrl.inProcess.profile = null;
                    });
            }
        }
    };

    setLearningConfig = (value) => {
        this.hasNewLearningParams = true;
        this.learningConfig.timeValue = value;
    }

    setNewCourses = () => {
        this.LearningService.courses(this.learningConfig, this,
            (ctrl, response) => {
                ctrl.errors.learning = false;
                ctrl.newCourses = response;
                angular.forEach(ctrl.newCourses, (newCourse) => {
                    let course = ctrl.learningConfig.courses.find(
                        (currentCourse) => {
                            return currentCourse.name == newCourse.name;
                        });
                    if (course) {
                        course.newId = newCourse.id;
                        course.newName = newCourse.name;
                    }
                });
                ctrl.learningConfig.coursesUpdated = 2;
                ctrl.saveLearning();
            }, (ctrl) => {
                ctrl.inProcess.learning = false;
                ctrl.errors.learning = true;
            });
    };

    saveLearning = () => {
        this.learningIsSaved = null;
        this.errors.learning = null;
        this.form.learning.$setSubmitted();
        if (this.form.learning.$valid) {
            this.inProcess.learning = true;
            if (!(this.learningConfig && this.lastLearningConfig && this.learningConfig.educationCount && ((this.learningConfig.location && this.lastLearningConfig.type != this.learningConfig.type) || (!this.learningConfig.location && this.lastLearningConfig.location)))) {
                this.learningConfig.coursesUpdated = 2;
            }
            if (!this.learningConfig.coursesUpdated) {
                this.updateCourses();
            } else {
                let learningConfig = angular.copy(this.learningConfig);
                if (!learningConfig.location) {
                    learningConfig.type = 2;
                }
                if (learningConfig.coursesUpdated != 2) {
                    this.setNewCourses();
                } else {
                    this.LearningService.saveLearningConfig(learningConfig, this,
                        (ctrl, response) => {
                            ctrl.inProcess.learning = null;
                            this.lastLearningConfig = learningConfig;
                            ctrl.learningConfig = response;
                            ctrl.learningIsSaved = true;
                            ctrl.$timeout(() => {
                                ctrl.learningIsSaved = null;
                            }, 2000);
                            if (window.EducationCtrl) {
                                window.EducationCtrl.retry(null, null);
                            }
                            this.hasNewLearningParams = false;
                        }, (ctrl, response) => {
                            if (response && response.data && response.data.error) {
                                ctrl.errors.learning = response.data.error;
                                if (response.data.field) {
                                    this.form.learning.$setValidity(
                                        response.data.field,
                                        false);
                                }
                            }
                            ctrl.inProcess.learning = null;
                            this.hasNewLearningParams = false;
                        });
                }
            }
        }
    };

    loadLdapProfiles = (firstLoading) => {
        if (this.appCtrl && this.appCtrl.$localStorage && this.appCtrl.$localStorage.userInfo && this.appCtrl.$localStorage.userInfo.license && this.appCtrl.$localStorage.userInfo.license.features && this.appCtrl.$localStorage.userInfo.license.features.manageLdap &&
            this.appCtrl.hasAccess(10, 'view')) {
            this.loading.ldap = true;
            try {
                let option = setOptions();
                fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-profiles`, option)
                    .then(response => {
                        if (!response.ok) {
                            throw response.error;
                        }
                        return response.json();
                    })
                    .then(profiles => {
                        this.ldapProfiles = profiles;
                        this.loading.ldap = false;
                        if (firstLoading) {
                            this.loadVariables(true);
                        }
                    });
            } catch (error) {
                this.loading.ldap = false;
                this.errors.ldap = true;
            }
        } else {
            if (firstLoading) {
                this.loadVariables(true);
            }
        }
    };

    reLoadLdapProfiles = () => {
        try {
            let option = setOptions();
            fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-profiles`, option)
                .then(response => {
                    if (!response.ok) {
                        throw response.error;
                    }
                    return response.json();
                })
                .then(profiles => {
                    this.ldapProfiles = profiles;
                    this.loading.ldap = false;
                });
        } catch (error) {
            this.loading.ldap = false;
            this.errors.ldap = true;
        }
    };

    loadMoreLdapProfiles = () => {
        this.loading.ldap = true;
        if (this.ldapProfileList.$existNext()) {
            const prevData = this.ldapProfileList.$data;
            this.ldapProfileList.$nextPage().then((data) => {
                this.ldapProfileList.$meta = data.$meta;
                this.ldapProfileList.$data = [...prevData, ...data.$data];
                this.ldapProfiles = this.ldapProfileList.$data;
                this.loading.ldap = false;
            });
        }
    };

    loadBossKadrovikProfiles = () => {
        if (this.appCtrl && this.appCtrl.hasAccess(10, 'view')) {
            this.loading.bossKadrovik = true;
            this.bossKadrovikProfileList.$page = 1;
            this.bossKadrovikProfileList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                this.bossKadrovikProfileList = data;
                this.bossKadrovikProfiles = this.bossKadrovikProfileList.$data;
                this.loading.bossKadrovik = false;
            });
        }
    };

    reLoadBossKadrovikProfiles = () => {
        this.loading.bossKadrovik = true;
        this.bossKadrovikProfileList.$page = 1;
        this.bossKadrovikProfileList.$load(this.bossKadrovikProfileList.$data.length).then((data) => {
            this.bossKadrovikProfileList = data;
            this.bossKadrovikProfiles = this.bossKadrovikProfileList.$data;
            this.loading.bossKadrovik = false;
        });
    };
    setRoles = (roles, roleList) => {
        this.roles = [...roles];
        this.roleList = roleList;
        // this.$scope.$apply();
    }

    loadMoreBossKadrovikProfiles = () => {
        this.loading.bossKadrovik = true;
        if (this.bossKadrovikProfileList.$existNext()) {
            const prevData = this.bossKadrovikProfileList.$data;
            this.bossKadrovikProfileList.$nextPage().then((data) => {
                this.bossKadrovikProfileList.$meta = data.$meta;
                this.bossKadrovikProfileList.$data = [...prevData, ...data.$data];
                this.bossKadrovikProfiles = this.bossKadrovikProfileList.$data;
                this.loading.bossKadrovik = false;
            });
        }
    };

    twoAuth = async (enabled = false) => {
        this.modal.pass = this.$injector.instantiate(TwoFactorPassModal);
        let next = true;
        if (!enabled) {
            this.modal.TFdisable = this.$injector.instantiate(TwoFactorDisableModal);
            next = await this.modal.TFdisable.open();
        }
        if (!next) return;
        next = await this.modal.pass.open(enabled);
        if (!next) return;
        if (!enabled) {
            this.setAuthProps({ usesSecondFactor: 0 });
            this.setOneTimeMessage(setDaemonsMessage(this.gettextCatalog.getString('Двухфакторная аутентификация выключена')));
        }
        if (enabled) {
            this.modal.code = this.$injector.instantiate(TwoFactorQRModal);
            next = await this.modal.code.open(next);
            if (next) {
                this.setAuthProps({ usesSecondFactor: 1 });
                this.setOneTimeMessage(setDaemonsMessage(this.gettextCatalog.getString('Двухфакторная аутентификация включена')));
            }
        }
    }

    saveBossKadrovikProfile = (profile) => {
        if (!this.appCtrl.hasAccess(13, 'boss_kadrovik_save')) {
            return;
        }
        this.modal.ldap = this.$injector.instantiate(BossKadrovikProfileModal);
        this.modal.ldap.open(profile && profile.id ? profile.id : 0).then((profileData) => {
            if (profileData) {
                if (profile && profile.id) {
                    this.reLoadBossKadrovikProfiles();
                } else {
                    this.loadBossKadrovikProfiles();
                }
            }
        }, () => {
        });
    };

    deleteBossKadrovikProfile = (profile) => {
        if (!this.appCtrl.hasAccess(13, 'boss_kadrovik_delete')) {
            return;
        }
        if (confirm(this.gettextCatalog.getString('Удалить БОСС-Кадровик профиль?'))) {
            this.BossKadrovikProfilesService.one(profile.id).then((profile) => {
                profile.$delete().then(() => {
                    this.reLoadBossKadrovikProfiles();
                });
            });
        }
        return false;
    };

    loadNotifications = (firstLoading) => {
        if (this.appCtrl && this.appCtrl.hasAccess(7, 'view')) {
            this.inactiveNotificationCount = 0;
            this.loading.notifications = true;
            this.notificationList.$page = 1;
            this.notificationList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                this.notificationList = data;
                this.notifications = this.notificationList.$data;
                this.recalcInactiveNotifications();
                this.loading.notifications = false;
                if (window.EducationCtrl) {
                    window.EducationCtrl.retry(null, null);
                }
                if (firstLoading) {
                    this.loadCourses(true);
                }
            });
        } else {
            if (firstLoading) {
                this.loadCourses(true);
            }
        }
    };

    recalcInactiveNotifications = () => {
        if (this.notifications && this.notifications.length > 0) {
            this.loading.notifications = true;
            this.inactiveNotificationCount = 0;
            angular.forEach(this.notifications, (notification) => {
                if (!+notification.status && ((!this.learningConfig.location && ['Registration', 'RegistrationLdap', 'Update', 'UpdateLdap'].indexOf(notification.type) == -1) ||
                    (this.learningConfig.location && ['AuthLink'].indexOf(notification.type) == -1 && (!this.learningConfig.type || ['Registration', 'RegistrationLdap', 'Update', 'UpdateLdap'].indexOf(notification.type) == -1)))) {
                    this.inactiveNotificationCount++;
                }
            });
            this.loading.notifications = false;
        }
    };

    loadSchedulerTasks = (firstLoading) => {
        if (this.appCtrl && this.appCtrl.hasAccess(9, 'view')) {
            this.loading.scheduler = true;
            this.schedulerTaskList.$page = 1;
            this.schedulerTaskList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                this.schedulerTaskList = data;
                this.schedulerTasks = this.schedulerTaskList.$data;
                this.loading.scheduler = false;
                if (firstLoading) {
                    this.loadLdapProfiles(true);
                }
            });
        } else {
            if (firstLoading) {
                this.loadLdapProfiles(true);
            }
        }
    };

    reLoadSchedulerTasks = () => {
        this.loading.scheduler = true;
        this.schedulerTaskList.$page = 1;
        this.schedulerTaskList.$load(this.schedulerTaskList.$data.length).then((data) => {
            this.schedulerTaskList = data;
            this.schedulerTasks = this.schedulerTaskList.$data;
            this.loading.scheduler = false;
        });
    };

    loadMoreSchedulerTasks = () => {
        this.loading.scheduler = true;
        if (this.schedulerTaskList.$existNext()) {
            const prevData = this.schedulerTaskList.$data;
            this.schedulerTaskList.$nextPage().then((data) => {
                this.schedulerTaskList.$meta = data.$meta;
                this.schedulerTaskList.$data = [...prevData, ...data.$data];
                this.schedulerTasks = this.schedulerTaskList.$data;
                this.loading.scheduler = false;
            });
        }
    };

    saveLdapProfile = (profile) => {
        this.modal.ldap = this.$injector.instantiate(LdapProfileModal);
        this.modal.ldap.open(profile && profile.id ? profile.id : 0,
            this.learningConfig.type == 0 ? 1 : 0).then((profileData) => {
            if (profileData) {
                if (profile && profile.id) {
                    this.reLoadLdapProfiles();
                } else {
                    this.loadLdapProfiles();
                }
            }
        }, () => {
        });
    };

    saveSchedulerTask = (task) => {
        if ((task && task.id) || (this.appCtrl && this.appCtrl.hasAccess(9, 'scheduler_save'))) {
            this.modal.scheduler = this.$injector.instantiate(SchedulerTaskModal);
            this.modal.scheduler.open(task && task.id ? task.id : 0, this).then((taskData) => {
                if (taskData) {
                    if (task && task.id) {
                        this.reLoadSchedulerTasks();
                    } else {
                        this.loadSchedulerTasks();
                    }
                }
            }, () => {
            });
        }
    };

    saveNotification = (notification) => {
        this.modal.notification = this.$injector.instantiate(NotificationModal);
        this.modal.notification.open(notification.id).then((notificationData) => {
            if (notificationData) {
                this.loadNotifications();
            }
        }, () => {
        });
    };

    loadRoles = async () => {
        try {
            let opt = setOptions();
            this.loadingRoles = true;
            let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + '/roles?fullList=1',opt);
            if (!response.ok) throw true;
            this.roles = await response.json();
            this.loadingRoles = false;
            this.$scope.$apply();
        } catch (e) {
            this.roleError = e;
            this.loadingRoles = false;
        }
    };

    deleteLdapProfile = (profile) => {
        if (confirm(this.gettextCatalog.getString('Удалить профиль LDAP?'))) {
            this.LdapProfilesService.one(profile.id).then((profile) => {
                profile.$delete().then(() => {
                    this.reLoadLdapProfiles();
                    this.loadRoles();
                });
            });
        }
        return false;
    };

    deleteSchedulerTask = (task) => {
        if (this.appCtrl && this.appCtrl.hasAccess(9, 'scheduler_delete') && confirm(this.gettextCatalog.getString('Удалить действие?'))) {
            this.SchedulerTasksService.one(task.id).then((task) => {
                task.$delete().then(() => {
                    this.reLoadSchedulerTasks();
                });
            });
        }
        return false;
    };

    courseName = (courseId, defaultText, useDefaultText) => {
        let course = this.cacheCourseById[courseId];
        if (typeof (course) == 'undefined') {
            course = this.courses.find((item) => {
                if (item.id == courseId) {
                    return true;
                }
            });
            this.cacheCourseById[courseId] = course;
        }
        if (course && course.id > 0) {
            return course.name;
        } else {
            return +courseId > 0 && !+useDefaultText
                ? this.gettextCatalog.getString('Курс не найден или больше не доступен')
                : defaultText;
        }
    };

    templateName = (templateId, defaultText) => {
        let template = this.cacheTemplateById[templateId];
        if (typeof (template) == 'undefined' && this.templates) {
            template = this.templates.find((item) => {
                if (item.id == templateId) {
                    return true;
                }
            });
            this.cacheTemplateById[templateId] = template;
        }
        if (template && template.id > 0) {
            return template.name;
        } else {
            return +templateId > 0
                ? this.gettextCatalog.getString('Шаблон не найден или больше не доступен')
                : defaultText;
        }
    };

    schedulerTriggerTypeDescription = (triggerTypeId) => {
        let triggerType = this.cacheSchedulerTriggerTypeById[triggerTypeId];
        if (typeof (triggerType) == 'undefined' && this.schedulerTriggerTypes) {
            triggerType = this.schedulerTriggerTypes.find((item) => {
                if (item.id == triggerTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerTriggerTypeById[triggerTypeId] = triggerType;
        }
        if (triggerType && triggerType.id) {
            return triggerType.description;
        }
    };

    schedulerTriggerTypeName = (triggerTypeId, defaultText, withoutDots) => {
        let triggerType = this.cacheSchedulerTriggerTypeById[triggerTypeId];
        if (typeof (triggerType) == 'undefined' && this.schedulerTriggerTypes) {
            triggerType = this.schedulerTriggerTypes.find((item) => {
                if (item.id == triggerTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerTriggerTypeById[triggerTypeId] = triggerType;
        }
        if (triggerType && triggerType.id) {
            let triggerName = triggerType.name;
            if (withoutDots) {
                triggerName = triggerType.name.replace(' ...', '');
            }
            return triggerName;
        } else {
            return +triggerTypeId > 0
                ? this.gettextCatalog.getString('Неизвестное событие')
                : defaultText;
        }
    };

    schedulerReportTypeName = (reportTypeId, defaultText) => {
        let reportType = this.cacheSchedulerReportTypeById[reportTypeId];
        if (typeof (reportType) == 'undefined' && this.schedulerReportTypes) {
            reportType = this.schedulerReportTypes.find((item) => {
                if (item.id == reportTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerReportTypeById[reportTypeId] = reportType;
        }
        if (reportType && reportType.id) {
            return reportType.name;
        } else {
            return +reportTypeId > 0
                ? this.gettextCatalog.getString('Неизвестный отчет')
                : defaultText;
        }
    };

    schedulerActionTypeName = (actionTypeId, defaultText, withoutDots) => {
        let actionType = this.cacheSchedulerActionTypeById[actionTypeId];
        if (typeof (actionType) == 'undefined' && this.schedulerActionTypes) {
            actionType = this.schedulerActionTypes.find((item) => {
                if (item.id == actionTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerActionTypeById[actionTypeId] = actionType;
        }
        if (actionType && actionType.id) {
            let actionName = actionType.name;
            if (withoutDots) {
                actionName = actionType.name.replace(' ...', '');
            }
            return actionName;
        } else {
            return +actionTypeId > 0
                ? this.gettextCatalog.getString('Неизвестное событие')
                : defaultText;
        }
    };

    toggleSchedulerTask = (task) => {
        const RULES = ['id', 'task_status', 'name', 'logic_mode'];
        if (task && +task.task_status > -1 && this.appCtrl && this.appCtrl.hasAccess(9, 'scheduler_status')) {
            this.schedulerTasksLoad = true;
            this.SchedulerTasksService.one(task.id).then((task) => {
                Object.keys(task).map(key => {
                    if (!RULES.includes(key)) {
                        delete task[key];
                    }
                });
                task.task_status = +!task.task_status;
                task.$save().then((taskData) => {
                    if (task.$errors && task.$errors.task_status && task.$errors.task_status.message) {
                        this.setOneTimeMessage({
                            text: task.$errors.task_status.message,
                            isReady: 'danger',
                            progress: 100
                        });
                        this.schedulerTasksLoad = false;
                        return;
                    }
                    if (taskData && taskData.id) {
                        let schedulerTask = this.schedulerTasks.find(
                            (item) => {
                                if (item.id == taskData.id) {
                                    return true;
                                }
                            });
                        schedulerTask.task_status = taskData.task_status;
                    }
                    this.schedulerTasksLoad = false;
                });
            });
        }
        return false;
    };

    signOut = () => {
        //
    };

    toggleNotificationStatus = (notification) => {
        if (+notification.status > -1) {
            this.NotificationsService.one(notification.id).then((notificationData) => {
                let notification = null;
                if (!notificationData.userId) {
                    notification = this.NotificationsService.YiiModel.one(
                        'notifications');
                    notification.name = notificationData.name;
                    notification.subject = notificationData.subject;
                    notification.domain = notificationData.domain;
                    notification.from = notificationData.from;
                    notification.from_name = notificationData.from_name;
                    notification.text = notificationData.text;
                    notification.type = notificationData.type;
                    notification.userId = 0;
                    notification.status = +!notificationData.status;
                    notification.$save().then(() => {
                        this.loadNotifications();
                    });
                } else {
                    notification = notificationData;
                    notification.status = +!notificationData.status;
                    notification.$update().then(() => {
                        this.loadNotifications();
                    });
                }
            });
        }
        return false;
    };

    loadVariables = (firstLoading) => {
        if (this.appCtrl && this.appCtrl.hasAccess(11, 'view')) {
            this.loading.variables = true;
            this.variableList.$page = 1;
            this.variableList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                this.variableList = data;
                this.variables = this.variableList.$data;
                this.loading.variables = false;
                if (firstLoading) {
                    this.loadNotifications(true);
                }
            });
        } else {
            if (firstLoading) {
                this.loadNotifications(true);
            }
        }
    };

    reLoadVariablws = () => {
        this.loading.variables = true;
        this.variableList.$page = 1;
        this.variableList.$load(this.variableList.$data.length).then((data) => {
            this.variableList = data;
            this.variables = this.variableList.$data;
            this.loading.variables = false;
        });
    };

    loadMoreVariables = () => {
        this.loading.variables = true;
        if (this.variableList.$existNext()) {
            const prevData = this.variableList.$data;
            this.variableList.$nextPage().then((data) => {
                this.variableList.$meta = data.$meta;
                this.variableList.$data = [...prevData, ...data.$data];
                this.variables = this.variableList.$data;
                this.loading.variables = false;
            });
        }
    };

    saveVariable = (variable) => {
        this.modal.variable = this.$injector.instantiate(VariableModal);
        this.modal.variable.open(variable.id).then((variableData) => {
            if (variableData) {
                this.loadVariables();
            }
        }, () => {
        });
    };

    saveUser = (user = null) => {
        this.modal.user = this.$injector.instantiate(UserModal);
        this.modal.user.open(user && user.id ? user.id : 0, this.userInfo).then((userData) => {
            if (userData && userData.id) {
                let newList = this.users.filter(item => item.id !== userData.id);
                newList.push(userData);
                this.users = [...newList];
            }
        }, () => {
        });
    };

    openPolicy = (user) => {
        this.modal.user = this.$injector.instantiate(UserPolicyModal);
        this.modal.user.open(user && user.id ? user.id : 0);
    };

    openAuthSettings = (user) => {
        let modal = this.$injector.instantiate(AuthSettingsModal);
        modal.open(user && user.id ? user.id : 0);
    };

    openMailServer = (serverId) => {
        this.$injector.instantiate(MailServerModal).open(serverId).then((server) => {
            if (server && server.id) {
                this.fetchSettings();
            }
        });
    };

    deleteMailServer = (serverId) => {
        if (confirm(this.gettextCatalog.getString('Удалить подключение к почте?'))) {
            this.MailServersService.delete(serverId).$promise.then(() => {
                this.fetchSettings();
            });
        }
    };

    getLearningParams = (params) => {
        if (!params) {
            this.hasNewLearningParams = true;
            return;
        }
        let keys = Object.keys(params);
        keys.forEach(item => {
            this.learningConfig[item] = params[item];
        });
        this.hasNewLearningParams = true;
    }

    detaildMailServer = (serverId, name) => {
        this.$localStorage.mailServerDetailsData = {
            'windowName': name,
        };
        this.$injector.instantiate(MailServerDetailsModal).open(serverId);
    };

    deleteUser = (user) => {
        if (this.appCtrl && this.appCtrl.hasAccess(101, 'user_delete') && confirm(this.gettextCatalog.getString('Удалить учетную запись {{email}}?', { email: user.login }))) {
            this.UsersService.one(user.id).then((user) => {
                user.$delete().then(() => {
                    this.users = this.users.filter(item => item.id !== user.id);
                });
            });
        }
        return false;
    };

    timeZoneSelected = (timeZone) => {
        angular.forEach(this.timeZones, (tz) => {
            if (tz.name == timeZone.name) {
                this.userInfo.timeZone = timeZone.name;
                this.userInfo.timeZoneGmt = timeZone.gmt;
            }
        });
        this.lastTimeZone = this.userInfo.timeZone;
        this.lastTimeZoneGmt = this.userInfo.timeZoneGmt;
        this.saveProfile();
    };

    timeZoneUnfocused = () => {
        let timeZone = this.timeZones.find((tz) => {
            return tz.name == this.userInfo.timeZone;
        });
        if (timeZone && timeZone.name) {
            this.userInfo.timeZone = timeZone.name;
            this.userInfo.timeZoneGmt = timeZone.gmt;
        } else {
            this.userInfo.timeZone = this.lastTimeZone;
            this.userInfo.timeZoneGmt = this.lastTimeZoneGmt;
        }
        this.saveProfile();
    };

    generatePassword = () => {
        if (this.enabledSavePolicy) {
            this.SettingsService.generatePassword({}, this,
                (ctrl, response) => {
                    if (response && response.password) {
                        ctrl.newPassword = response.password;
                    }
                }, () => {
                    // TODO: display error in 2.4.1
                });
        }
    };

    updateCourses = () => {
        let learningConfig = angular.copy(this.learningConfig);
        if (typeof (learningConfig.$promise) != 'undefined') {
            delete learningConfig.$promise;
        }
        if (typeof (learningConfig.$resolved) != 'undefined') {
            delete learningConfig.$resolved;
        }
        if (!learningConfig.location) {
            learningConfig.type = 2;
        }
        this.modal.courses = this.$injector.instantiate(CoursesModal);
        this.modal.courses.open(learningConfig).then((learningConfig) => {
            if (learningConfig) {
                delete learningConfig.type;
                this.learningConfig = angular.extend({},
                    this.learningConfig, learningConfig);
                this.learningConfig.coursesUpdated = 2;
                if (this.inProcess.learning) {
                    this.saveLearning();
                }
            } else {
                this.learningConfig.coursesUpdated = 0;
                this.inProcess.learning = false;
            }
        }, () => {
            this.learningConfig.coursesUpdated = 0;
            this.inProcess.learning = false;
        });
    };

    saveCourse = (course) => {
        this.modal.course = this.$injector.instantiate(CourseModal);
        this.modal.course.open(course && course.id ? course.id : 0).then((courseData) => {
            if (courseData) {
                this.loadCourses();
            }
        }, () => {
        });
    };

    deleteCourse = (course) => {
        if (this.appCtrl && this.appCtrl.hasAccess(8, 'course_delete') && confirm(this.gettextCatalog.getString('Удалить курс?'))) {
            this.CoursesService.one(course.id).then((course) => {
                course.$delete().then(() => {
                    this.loadCourses();
                });
            });
        }
        return false;
    };

    loadCourses = (firstLoading) => {
        if (this.appCtrl &&
            ((this.appCtrl.$localStorage && this.appCtrl.$localStorage.userInfo && this.appCtrl.$localStorage.userInfo.license && this.appCtrl.$localStorage.userInfo.license.features && this.appCtrl.$localStorage.userInfo.license.features.manageCourse && this.appCtrl.hasAccess(8, 'view')) ||
                this.appCtrl.hasAccess(9, 'view'))) {
            this.loading.courses = true;
            this.courseList.$load().then((data) => {
                this.courseList = data;
                this.courses = this.courseList.$data;
                this.loading.courses = false;
                if (firstLoading) {
                    this.loadMailServers(true);
                }
            });
        } else {
            if (firstLoading) {
                this.loadMailServers(true);
            }
        }
    };

    loadMailServers = (firstLoading) => {
        if (this.appCtrl && this.appCtrl.$localStorage && this.appCtrl.$localStorage.userInfo && this.appCtrl.$localStorage.userInfo.license && this.appCtrl.$localStorage.userInfo.license.features && this.appCtrl.$localStorage.userInfo.license.features.mailServer &&
            this.appCtrl.hasAccess(12, 'view')) {
            this.loading.mailServers = true;
            this.mailServerList.$load().then((data) => {
                this.mailServerList = data;
                this.mailServers = this.mailServerList.$data;
                this.loading.courses = false;
                if (firstLoading) {
                    this.loadBossKadrovikProfiles(true);
                }
            });
        } else {
            if (firstLoading && this.$localStorage.userInfo.targetImport && this.$localStorage.userInfo.targetImport.bossKadrovikEnabled && this.$localStorage.userInfo.license.features.manageLdap) {
                this.loadBossKadrovikProfiles(true);
            }
        }
    };

    setLearningLocation = (location) => {
        if (this.appCtrl.hasAccess(6, 'education_save')) {
            if (this.learningConfig.location !== location) {
                this.hasNewLearningParams = true;
            }
            this.learningConfig.location = location || 0;
            if (location) {
                this.learningConfig.type = this.learningConfig.type != 2 ? this.learningConfig.type : 0;
            }
            this.learningIsSaved = null;
            this.recalcInactiveNotifications();
        }
    };

    setLearningTimePeriod = (timePeriod) => {
        if (this.appCtrl.hasAccess(6, 'education_save')) {
            this.learningConfig.timePeriod = timePeriod || 'day';
            this.getLearningParams();
        }
    };

    isValidDepartment = () => {
        if (!this.loading.userInfo && this.userInfo.department) {
            let departmentName = typeof (this.userInfo.department) == 'string' ? this.userInfo.department : '';
            if (this.userInfo.department && this.userInfo.department.fullName) {
                departmentName = this.userInfo.department.fullName;
            }
            if (departmentName) {
                let department = this.departments.find((department) => {
                    return department.fullName == departmentName;
                });
                if (department && department.id) {
                    if (!this.userInfo.department || !this.userInfo.department.id) {
                        this.userInfo.department = department || null;
                    }
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
        if (typeof (this.userInfo.department) == 'string' && this.userInfo.department.indexOf('/') == -1) {
            return true;
        }
    };

    checkDepartmentMatches = () => {
        this.departmentHasMatches = false;
        if (this.departments.length > 0) {
            let departmentName = this.userInfo.department && this.userInfo.department.fullName ? this.userInfo.department.fullName : (typeof (this.userInfo.department) == 'string' ? this.userInfo.department : '');
            angular.forEach(this.departments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.departmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    departmentSelected = (department) => {
        if (this.departments) {
            if (typeof (department) == 'string') {
                department = this.departments.find((item) => {
                    return item.fullName == this.userInfo.department;
                });
            }
            angular.forEach(this.departments, (item) => {
                if (item.fullName == department.fullName) {
                    this.userInfo.department = item || null;
                }
            });
            this.lastDepartment = this.userInfo.department || null;
            this.saveProfile();
        }
    };

    departmentUnfocused = () => {
        if (this.departments.length > 0) {
            let department = null;
            if (typeof (this.userInfo.department) == 'string') {
                department = this.departments.find((item) => {
                    return item.fullName == this.userInfo.department;
                });
            }
            if (!department && this.userInfo.department && this.userInfo.department.id) {
                department = this.userInfo.department || null;
            }
            this.userInfo.department = department || null;
            this.saveProfile();
        }
    };

    focusedOn = (focusField, callback = null) => {
        if (this.hashPath === focusField && this.hashPath !== 'targetReport') {
            if (focusField === 'ldapField' || focusField === 'ldapSaveField') {
                scroller(`#${focusField}`, 110);
            } else {
                scroller(`#${focusField}`, 60);
            }
            this.setLinkings('');
            if (callback) {
                callback();
            }
        }
    };

    getLoad = () => {
        this.reactLoad = true;
    }

    syncLdap = (profile) => {
        if (profile && profile.id) {
            profile.lastImport = null;
            profile.loading = true;
            profile.error = false;
            this.appCtrl.manualLdapSyncLoading = true;
            this.LdapProfilesService.action(profile, 'sync', this, (profile, ctrl, responce) => {
                ctrl.appCtrl.manualLdapSyncLoading = false;
                profile.loading = false;
                if (responce && responce.id) {
                    profile.lastImport = responce;
                    if (responce.completed == -2) {
                        profile.info = ctrl.gettextCatalog.getString('В профиле не найдено изменений');
                        this.$timeout(() => {
                            profile.info = null;
                        }, 3000);
                    }
                }
            }, (profile, ctrl, response) => {
                ctrl.appCtrl.manualLdapSyncLoading = false;
                profile.loading = false;
                profile.error = ctrl.gettextCatalog.getString('Ошибка при синхронизации сотрудников');
                if (response.data) {
                    if (response.data.error) {
                        profile.error = response.data.error;
                    }
                    if (response.data.message) {
                        profile.error = response.data.message;
                    }
                    if (response.status && response.status == 504) {
                        profile.error = ctrl.gettextCatalog.getString('Превышен лимит ожидания синхронизации сотрудников');
                    }
                }
            });
        }
    };

    isValidEmail = (email) => {
        let isValid = false;
        if (this.domains && this.domains.length) {
            let regexp = new RegExp('(\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,6})', 'gim');
            isValid = email && email.match(regexp);
            if (isValid) {
                isValid = false;
                angular.forEach(this.domains, (domain) => {
                    regexp = new RegExp('(\\w+@' + domain.replace('.', '\\.') + ')', 'gim');
                    if (email.match(domain)) {
                        isValid = true;
                    }
                });
            }
        }
        return isValid;
    };

    logout = () => {
        const { $localStorage, $location } = this;
        let url = this.isSSO ? '/sso/logout' : '/logout';
        let opts = setOptions();
        fetch(url, opts).then((response) => {
            return response.json();
        }).then((res) => {
            if (res.ssoRedirectUrl) {
                window.location.href = res.ssoRedirectUrl;
            } else {
                $localStorage.userInfo = null;
                $location.path('login');
                this.$scope.$apply();
            }
        });
    };

    isValidEmails = (emails) => {
        let isValid = true;
        if (emails && emails.length) {
            angular.forEach(emails, (email) => {
                if (!this.isValidEmail(email)) {
                    isValid = false;
                }
            });
        }
        return isValid;
    };

    setLinkMode = (mode) => {
        if (this.appCtrl.hasAccess(6, 'education_save')) {
            this.learningConfig.linkMode = mode;
            this.getLearningParams();
        }
    }

    onAfterRoleSync = () => {
        this.loadUsers();
    };

    loadUsers = () => {
        if (this.appCtrl && this.appCtrl.hasAccess(101, 'view')) {
            this.loading.users = true;
            this.userList.$page = 1;
            this.userList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                this.userList = data;
                this.users = this.uniqueUsers(this.userList.$data);
                this.loading.users = false;
            }, () => {});
        }
    };

    uniqueUsers = (data) => {
        let users = [...this.users];
        let oldUsers = this.users.map(item => item.id);
        data.forEach(function(dataItem) {
            let i = oldUsers.indexOf(dataItem.id);
            if (i === -1) {
                users.push(dataItem);
            } else {
                users[i] = dataItem;
            }
        });
        return users;
    };

    loadMoreUsers = () => {
        this.loading.users = true;
        if (this.userList.$existNext()) {
            const prevData = this.userList.$data;
            this.userList.$nextPage().then((data) => {
                this.userList.$meta = data.$meta;
                this.userList.$data = [...prevData, ...data.$data];
                this.users = this.userList.$data;
                this.loading.users = false;
            });
        }
    };

    $onDestroy() {
        this.langDefault();
        this.unsubscribe();
    }
}

SettingsController.$inject = [
    '$injector',
    '$scope',
    '$localStorage',
    '$timeout',
    'FileUploader',
    'FileReader',
    AUTH_SERVICE,
    MAIL_SERVERS_SERVICE,
    SETTINGS_SERVICE,
    LEARNING_SERVICE,
    LDAP_PROFILES_SERVICE,
    SCHEDULER_TASKS_SERVICE,
    NOTIFICATIONS_SERVICE,
    VARIABLES_SERVICE,
    USERS_SERVICE,
    TIME_ZONES_SERVICE,
    COURSES_SERVICE,
    ROLES_SERVICE,
    '$ngRedux',
    '$location',
    '$anchorScroll',
    BOSS_KADROVIK_SERVICE,
];

export {
    SettingsController,
};
