import {fetchAuth, setTargetCount, toggleSecondFactor} from './auth';
import {fetchDaemonsMessage} from './daemons';
import {fetchDashboardParams} from './dashboardParams';
import {fetchDepartments} from './departments';
import {fetchLang} from './languages';
import {fetchVariables} from './variables';
import {fetchCerts, deleteCerts} from './certificate';
import {fetchSSO, fetchSSOPost, deleteSSO, fetchSSOPut} from './ssoProfie';
import {fetchAttackHistory} from './attackHistory';
import {fetchEducationHistory} from './educationHistory';
import {fetchLearningConfig} from './learningConfig';
import {fetchLdapDepartments} from './ldapDepartments';
import {fetchMotivationHistory} from './motivationHistory';
import { fetchTargetHistory } from './targetHistory';
import { fetchSettings } from './settings';
import {fetchNotification, systemNotificationPut, systemNotificationToggle} from './systemNotification';
import {fetchCoursesSettings, deleteCourses} from './courses';
import {fetchAccount, deleteAccount} from './accountUsers';
import {fetchRoles, deleteRole} from './roleUsers';
import {fetchOutlookSettings, fetchOutlookPost, saveCardSettings} from './outlookSettings';
import {fetchListWifi, deleteWifi} from './wifi'
import {fetchListWifiDevice, deleteWifiDevice} from './wifiDevice';
import {fetchPhishingPages} from './phishingPage';
import {fetchFinalPages} from './finalPages';
import {fetchWifiTargets} from './wifiTargets';
import {fetchListNotification, deleteNotification, toDefaultOneNotify} from './notificationsList';
import {fetchLdapProfiles} from './ldapProfiles';
import {fetchSchedulerTasksStatus, deleteSchedulerTasks, toggleSchedulerTask} from './schedulerTasks';

export {
    fetchAuth,
    fetchDepartments,
    fetchVariables,
    fetchDashboardParams,
    fetchDaemonsMessage,
    fetchLang,
    fetchCerts,
    fetchSSO,
    fetchAttackHistory,
    fetchEducationHistory,
    fetchLearningConfig,
    fetchLdapDepartments,
    fetchMotivationHistory,
    fetchTargetHistory,
    fetchSSOPost,
    deleteSSO,
    fetchSSOPut,
    setTargetCount,
    toggleSecondFactor,
    fetchSettings,
    fetchNotification,
    systemNotificationPut,
    systemNotificationToggle,
    fetchCoursesSettings,
    deleteCourses,
    deleteCerts,
    fetchAccount,
    deleteAccount,
    fetchRoles,
    deleteRole,
    fetchOutlookPost,
    fetchOutlookSettings,
    saveCardSettings,
    fetchListWifi,
    deleteWifi,
    fetchListWifiDevice,
    deleteWifiDevice,
    fetchFinalPages,
    fetchPhishingPages,
    fetchWifiTargets,
    fetchListNotification,
    deleteNotification,
    fetchLdapProfiles,
    toDefaultOneNotify,
    fetchSchedulerTasksStatus,
    deleteSchedulerTasks,
    toggleSchedulerTask,
};
