import { AUTH_SERVICE, EMPTY_SELECTED_DATA, SCHEDULER_TASKS_SERVICE, TARGETS_SERVICE, TIME_ZONES_SERVICE, USERS_SERVICE } from '../../const';
import style from './style.modules.css';
import angular from 'angular';
import rebuildSelectedData from '../../helpers/rebuildSelectedData';
import * as moment from 'moment-timezone';
import { Trigger } from '../../models/scheduler-trigger';
import * as momentTimezone from 'moment-timezone';
import { DateTime } from 'luxon';
import {
    notExtensibleTemplate,
    notExtensibleCourses,
    notExtensibleTrigger,
    notExtensibleReport,
    notExtensibleAction, customNotification, hasAccess
} from '../../reselect';
import { setAuthProps, clearSelectedData, setLinkings } from '../../toolkit/actions';

const defaultLearningDeadlineUnit = 'week';
const defaultLearningDeadlineValue = 1;

class SchedulerTaskModalController {
    constructor (SchedulerTasksService, UsersService, TargetsService, AuthService, $localStorage, $scope, taskId, gettextCatalog, settingsCtrl, $ngRedux) {
        this.SchedulerTasksService = SchedulerTasksService;
        this.UsersService = UsersService;
        this.TargetsService = TargetsService;
        this.AuthService = AuthService;
        this.$localStorage = $localStorage;
        this.$scope = $scope;
        this.taskId = taskId;
        this.AuthService = AuthService;
        this.coursesList = [];
        if ($localStorage.schedulerTaskData && $localStorage.schedulerTaskData.schedulerTaskId) {
            this.taskId = $localStorage.schedulerTaskData.schedulerTaskId;
        }
        if ($localStorage.schedulerTaskSelectedData && $localStorage.schedulerTaskSelectedData.schedulerTaskId) {
            this.taskId = $localStorage.schedulerTaskSelectedData.schedulerTaskId;
        }
        this.processing = false;
        this.task = null;
        this.gettextCatalog = gettextCatalog;
        this.appCtrl = window.appCtrl;
        this.users = [];
        this.domains = [];
        this.isEdit = false;
        this.style = style;
        this.domainsVisible = 3;
        this.settingsCtrl = settingsCtrl;
        this.confirmMessage = gettextCatalog.getString('Правило не сохранено, и все изменения будут потеряны. Уверены?');
        this.inSchedulerCount = 0;
        this.targetCount = 0;
        this.AuthService.getUserInfo('targetCount').$promise.then((userInfo) => {
            this.targetCount = userInfo.targetCount || 0;
        });
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setAuthProps, clearSelectedData, setLinkings })(this);
        this.isOpen = false;
        this.templateNameChosen = '';
        this.timeTo = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        this.isOpenTo = false;
        this.timeZones = settingsCtrl.timeZones || [];
        this.dateOptions = {
            minDate: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
            maxDate: null
        };
        this.showTimer = false;
        this.lastTimeZone = null;
        this.selectNotifications = null;
        this.selectNotifiError = false;
        this.lastTimeZoneGmt = null;
    }

    mapStateToThis = (state) => {
        return {
            triggerTypes: notExtensibleTrigger(state),
            actionTypes: notExtensibleAction(state),
            reportTypes: notExtensibleReport(state),
            courses: notExtensibleCourses(state),
            templates: notExtensibleTemplate(state),
            learningConfig: state.learningConfig.config,
            coursesLoading: state.courses.loading,
            userInfo: state.auth.auth,
            notifications: customNotification(state),
            notificationsView: hasAccess(state, { sectionId: 7, rightName: 'view' })
        };
    };

    getActionTypes = () => {
        let setNotify = true;
        this.task.actions.forEach(item => {
            if (item.type === 'target_notify') {
                setNotify = false;
            }
        });
        return this.actionTypes.filter(item => {
            if (setNotify) return true;
            if (item.id === 'target_notify') return false;
            return true;
        });
    }

    selectAll = () => {
        this.task.selectedData = null;
    }

    openCalendar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.isOpen = true;
        this.isOpenTo = false;
    };

    openCalendarTo = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.isOpen = false;
        this.isOpenTo = true;
    };

    setForm = (form) => {
        this.form = form;
    };

    getTimeZone = (tzName) => {
        let timeZone;
        angular.forEach(this.timeZones, (tz) => {
            if (tz.name === tzName) {
                timeZone = tz;
            }
        });
        return timeZone;
    }

    newTrigger = (t) => {
        let tzName = t.time_zone || this.userInfo.timeZone || 'UTC';
        let tz = this.timeZones.find((timeZone) => {
            return  tzName === timeZone.name;
        });
        if (! tz) {
            tz = { name: 'UTC', gmt: '+0' };
        }
        if (this.userInfo.timeZone) {
            this.setAuthProps({ timeZoneGmt: tz.gmt });
        }
        //return this.createTrigger(trigger.trigger_value, trigger.trigger_mode, trigger.day_value, trigger.type, tz);
        let trigger = new Trigger(t.trigger_value, t.trigger_mode, t.day_value, t.type, t.start_date, tz, t.dateTimeString, t.courses);
        //trigger.setTimeZone(tz);
        return trigger;
    };

    loadTask = () => {
        this.errorTask = false;
        this.SchedulerTasksService.one(this.taskId).then((task) => {
            this.task = task;
            this.taskEditable = true;
            if (!this.task.delay_value && !this.task.action_value) {
                this.delayType = 0;
            } else if (!this.task.action_value && this.task.delay_value > 0) {
                this.delayType = 1;
            } else if (this.task.action_value > 0) {
                this.delayType = 2;
            }
            if (!this.task.action_period) {
                this.task.action_period = 'hour';
            }
            // для экшенов если он
            if (this.task.actions && this.task.triggers.actions !== 0) {
                this.task.actions.forEach(action => {
                    if (action.type === 'target_course' && ((action.action_period === 'week' || action.action_period === 'day') && action.action_value === 0)) {
                        action.action_value = 1;
                    }
                });
            }
            this.task.actions.forEach(item => {
                if (item.customNotificationUuid) {
                    this.selectNotifications = this.notifications.filter(notifi => notifi.uuid === item.customNotificationUuid)[0];
                }
            });
            if (this.task.triggers && this.task.triggers.length !== 0) {
                let trg = [];
                this.task.triggers.forEach(trigger => {
                    trg.push(this.newTrigger(trigger));
                });
                this.task.triggers = trg;
            }
            this.afterTaskLoading();
        }, () => {
            this.loadingTask = false;
            this.errorTask = true;
        }).catch(() => {
            this.loadingTask = false;
            this.errorTask = true;
        });
    };

    notifiSelected = () => {
        this.selectNotifiError = false;
    }

    setActionPeriod = (action, period) => {
        if ('action_period' in action) {
            period = period || defaultLearningDeadlineUnit;
            action.action_period = period;
        }
    };

    afterTaskLoading = () => {
        this.editable = this.task.editable && this.editable;
        const { $localStorage } = this;
        if ($localStorage.schedulerTaskData &&
            $localStorage.schedulerTaskData.schedulerTask) {
            this.task = angular.extend(this.task, $localStorage.schedulerTaskData.schedulerTask);
            this.task.id = $localStorage.schedulerTaskData.schedulerTaskId;
            this.taskId = $localStorage.schedulerTaskData.schedulerTaskId;
            if (this.task.actions &&
                this.task.actions[+$localStorage.schedulerTaskData.schedulerActionKey] &&
                this.task.actions[+$localStorage.schedulerTaskData.schedulerActionKey].report_to) {
                this.task.actions[+$localStorage.schedulerTaskData.schedulerActionKey].report_to.selectedData = $localStorage.schedulerTaskData.selectedData;
            }
            delete $localStorage.schedulerTaskData;
        }
        if ($localStorage.schedulerTaskSelectedData &&
            $localStorage.schedulerTaskSelectedData.schedulerTask) {
            this.task = angular.extend(this.task, $localStorage.schedulerTaskSelectedData.schedulerTask);
            this.task.id = $localStorage.schedulerTaskSelectedData.schedulerTaskId;
            this.taskId = $localStorage.schedulerTaskSelectedData.schedulerTaskId;
            delete $localStorage.schedulerTaskSelectedData;
        }
        this.loadAdministrators(() => {
            this.loadTargets();
        });
        if (typeof this.task.actions[0].template_id !== 'undefined') {
            this.templateNameChosen = this.getActionTemplateName(this.task.actions[0].template_id);
        }
        this.actionNameCoosen = this.getActionTypeName();
    };

    $onInit = () => {
        const { $localStorage } = this;
        this.delayType = 0;
        this.loadingTask = true;
        this.hasValidCourses = false;
        if (this.courses) {
            this.coursesList = this.courses.map((s) => {
                if (s.valid) {
                    this.hasValidCourses = true;
                }
                return { id: s.id, name: s.name, valid: s.valid };
            });
        }
        if (!this.courses.length) {
            this.hasValidCourses = true;
        }
        if (this.taskId) {
            this.loadTask();
        } else {
            this.task = this.SchedulerTasksService.YiiModel.one('scheduler-tasks');
            this.task.selectedDirection = 'up';
            this.task.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), { all: 1, selectedCount: { targets: +this.targetCount } });
            if (!$localStorage.schedulerTaskSelectedData ||
                !$localStorage.schedulerTaskSelectedData.schedulerTask) {
                this.rebuildSelection();
            }
            // let trig = this.createTrigger(0, null, 0, null);
            let trig = this.newTrigger({ trigger_value:0,trigger_mode:null,day_value:0,type:null });
            this.task.triggers = [trig];
            this.task.actions = [{}];
            this.task.task_status = 1;
            this.task.logic_mode = 'and';
            this.task.action_period = 'hour';
            this.afterTaskLoading();
        }
        this.errorText = null;
        this.triggerTypeName = this.settingsCtrl.schedulerTriggerTypeName;
        this.triggerTypeDescription = this.settingsCtrl.schedulerTriggerTypeDescription;
        this.actionTypeName = this.settingsCtrl.schedulerActionTypeName;
        this.reportTypeName = this.settingsCtrl.schedulerReportTypeName;
        this.courseName = this.settingsCtrl.courseName;
        this.templateName = this.settingsCtrl.templateName;
        this.domains = this.settingsCtrl.domains;
        this.errors = {
            triggerType: null,
            actionType: null,
            submit: null
        };
        this.editable = this.appCtrl && this.appCtrl.hasAccess(9, 'scheduler_save');
    };

    getActionTypeName = () => {
        let actionItem = this.actionTypes.filter(item => {
            return item.id === this.task.actions[0].type;
        });
        let actionChosen = actionItem.length ? actionItem[0].name : '';
        return actionChosen;
    }

    getActionTemplateName = (id) => {
        let templates = this.templates;
        let template = templates.filter(item => {
            return item.id === id;
        });
        let templateChosen = template.length ? template[0].name : '';
        return templateChosen;
    }

    closeModal = () => {
        if (this.lastTask !== angular.toJson(this.task)) {
            if (confirm(this.confirmMessage)) {
                this.$close();
            }
        } else {
            this.$close();
        }
    };

    setDelayType = (value) => {
        this.delayType = value;
        this.errors.triggerType = false;
        this.validateTriggers();
    };

    getNotificationsList = () => {
        return this.notifications.filter(item => {
            if (!item.type && !item.courses.length) {
                return true;
            }
        });
    }

    setNotifyAction = () => {
        let isNotify = false;
        let i = null;
        this.task.actions.forEach((item, j) => {
            if (item.type === 'target_notify') {
                isNotify = true;
                i = j;
            }
        });
        if (isNotify && this.selectNotifications) {
            this.task.actions[i].customNotificationUuid = this.selectNotifications.uuid;
        }
    }

    save = () => {
        if (this.editable) {
            this.errorText = null;
            this.form.$setSubmitted();
            this.validateTriggers(true);
            this.validateActions(true);
            if (this.form.$valid) {
                if (this.delayType == 2) {
                    this.task.delay_value = 0;
                    this.task.action_value = this.task.action_value > 0 ? this.task.action_value : 1;
                } else if (this.delayType == 1) {
                    this.task.action_value = 0;
                    this.task.delay_value = this.task.delay_value > 0 ? this.task.delay_value : 0;
                } else {
                    this.task.action_value = 0;
                    this.task.delay_value = 0;
                }
                if (this.task.triggers && this.task.triggers.length > 0 && this.task.actions && this.task.actions.length > 0) {
                    if (this.task.triggers.length < 2 && !this.task.triggers[0].type) {
                        this.errors.triggerType = true;
                        this.errorText = this.gettextCatalog.getString('Не выбрано событие');
                    }
                    if (this.task.actions.length < 2 && !this.task.actions[0].type) {
                        this.errors.actionType = true;
                        this.errorText = this.gettextCatalog.getString('Не выбрано действие');
                    }
                    if (
                        !this.errors.triggerType
                        && !this.errors.actionType
                        && this.validateTriggers(true)
                        && this.validateActions(true)
                        && (this.isMomentaryTask() || +this.delayType > 0 || this.task.triggers.length > this.inSchedulerCount)
                    ) {
                        this.inProcess = true;
                        if (this.inSchedulerCount > 0 && !this.delayType) {
                            this.task.logic_mode = 'and';
                        }
                        if (!this.task.selectedData || +this.task.selectedData.selectedCount.targets == +this.targetCount) {
                            this.task.selectedData = null;
                        }
                        this.setNotifyAction();
                        this.task.$save().then((task) => {
                            this.clearSelectedData();
                            if (task) {
                                this.setLinkings('scheduler');
                                this.$close(task);
                            }
                            this.inProcess = false;
                        }).catch(() => {
                            this.errorText = this.gettextCatalog.getString('Ошибка при сохранении правила');
                        });
                    }
                } else {
                    this.errors.submit = true;
                    if (!this.task.triggers || !this.task.triggers.length) {
                        this.errors.triggerType = true;
                        this.errorText = this.gettextCatalog.getString('Не добавлено событие');
                    } else {
                        this.errors.triggerType = null;
                    }
                    if (!this.task.actions || !this.task.actions.length) {
                        this.errors.actionType = true;
                        this.errorText = this.gettextCatalog.getString('Не добавлено действие');
                    } else {
                        this.errors.actionType = null;
                    }
                }
            }
        }
    };

    close = () => {
        if (this.isEdit) {
            let result = confirm(this.gettextCatalog.getString('закрыть?'));
            if (!result) {
                return;
            }
        }
        this.clearSelectedData();
        this.$close();
    };

    getRange = (scope) => {
        const range = [];
        for (let i = scope.trigger_value - 10; i < scope.trigger_value + 10; i++) {
            range.push(+i);
        }
        return range;
    };

    setTaskLogicMode = (mode) => {
        if (this.editable) {
            this.task.logic_mode = mode;
        }
    };

    addTrigger = () => {
        if (this.editable) {
            if (typeof (this.task.triggers) == 'undefined') {
                this.task.triggers = [];
            }
            if (this.task.triggers[0] && this.task.triggers[0].type) {
                // let trig = this.createTrigger(0, this.trigger_mode, 0, null);
                let trig = this.newTrigger({ trigger_value:0,trigger_mode:this.trigger_mode,day_value:0,type:null });
                this.task.triggers.unshift(trig);
                this.clearErrors('triggerType');
            } else {
                this.errors.triggerType = true;
            }
            this.isEdit = true;
        }
        this.validateTriggers();
    };

    removeTrigger = (triggerKey) => {
        if (this.editable) {
            this.task.triggers.splice(triggerKey, 1);
        }
    };

    addAction = () => {
        if (this.editable) {
            if (typeof (this.task.actions) == 'undefined') {
                this.task.actions = [];
            }
            if (this.task.actions[0] && this.task.actions[0].type) {
                this.task.actions.unshift({
                    action_value: 1,
                    action_mode: null,
                    delay_value: 0,
                    delay_period: 'minute'
                });
                this.clearErrors('actionType');
            } else {
                this.errors.actionType = true;
            }
        }
        this.validateActions();
    };

    removeAction = (actionKey) => {
        if (this.editable) {
            this.task.actions.splice(actionKey, 1);
        }
    };

    clearErrors = (errorType) => {
        this.errors[errorType] = null;
        this.errors.submit = null;
    };

    onRemoveCourse = (data, $item) => {
        if (this.editable) {
            data.courses.forEach((item, key) => {
                if (item.item.id == $item.id) {
                    data.courses.splice(1, key);
                }
            });
        }
    };

    onSelectCourse = (data, $item) => {
        if (this.editable) {
            data.courses.push({
                id: $item.id
            });
        }
    };

    validateTriggers = (removeEmpty = false) => {
        let valid = true;
        if (this.task) {
            this.inSchedulerCount = 0;
            this.task.triggers.forEach((trigger, triggerKey) => {
                if (trigger.error) {
                    valid = false;
                    return;
                }
                if (!trigger.type) {
                    if (removeEmpty && this.task.triggers.length > 1) {
                        this.task.triggers.splice(triggerKey, 1);
                    } else {
                        valid = false;
                        if (this.form?.$submitted) {
                            this.errors.triggerType = true;
                        }
                    }
                } else {
                    let triggerType = this.triggerTypes.find((item) => {
                        return item.id === trigger.type;
                    });
                    if (trigger.type.indexOf('course') > -1 && trigger.trigger_mode == 'many' && (!trigger.courses || trigger.courses.length == 0)) {
                        valid = false;
                    }
                    if (triggerType.inScheduler) {
                        this.inSchedulerCount++;
                        if (this.isMomentaryTask()) {
                            if (this.delayType > 0) {
                                this.setDelayType(0);
                            }
                        } else {
                            if (!+this.delayType) {
                                this.task.logic_mode = 'and';
                                if (this.task.triggers.length <= this.inSchedulerCount) {
                                    valid = false;
                                    if (this.task.triggers.length <= 1) {
                                        this.setDelayType(2);
                                    }
                                }
                            }
                        }
                    }
                }
            });
            if (this.task.triggers.length < 2 && (!this.task.triggers[0] || !this.task.triggers[0].type)) {
                valid = false;
            }
        } else {
            valid = false;
        }
        return valid;
    };

    isReportSelected = (action) => {
        var reportSelected = false;
        if (this.reportTypes && this.reportTypes.length) {
            this.reportTypes.forEach(function (reportType) {
                if (action[reportType.id] == true) {
                    reportSelected = true;
                }
            });
        }
        return reportSelected;
    };

    validateActions = (removeEmpty = false) => {
        var valid = true;
        if (this.task) {
            this.task.actions.forEach((action, actionKey) => {
                if (action && action.type && action.type.indexOf('notify') > -1 && !this.selectNotifications) {
                    this.selectNotifiError = true;
                    valid = false;
                }
                if (!action.type) {
                    if (removeEmpty && this.task.actions.length > 1) {
                        this.task.actions.splice(actionKey, 1);
                    } else {
                        valid = false;
                        if (this.form.$submitted) {
                            this.errors.actionType = true;
                        }
                    }
                } else {
                    if (action.type == 'report_admin') {
                        if (!this.isReportSelected(action)) {
                            valid = false;
                        }
                        if (valid && this.settingsCtrl && (!action.report_to || (!action.report_to.type &&
                                (!action.report_to.administrators ||
                                    !action.report_to.administrators.length)) ||
                            (action.report_to.type == 1 &&
                                (!action.report_to.targets ||
                                    !action.report_to.targets.length)) ||
                            (action.report_to.type == 2 &&
                                (!action.report_to.emails ||
                                    !action.report_to.emails.length ||
                                    !this.settingsCtrl.isValidEmails(
                                        action.report_to.emails)))
                        )) {
                            valid = false;
                        }
                    }
                    if (action.type.indexOf('course') > -1 && action.action_mode == 'many' && (!action.courses || action.courses.length == 0)) {
                        valid = false;
                    }
                }
            });
            if (this.task.actions.length < 2 && (!this.task.actions[0] || !this.task.actions[0].type)) {
                valid = false;
            }
        } else {
            valid = false;
        }
        return valid;
    };

    loadAdministrators = (callback) => {
        this.UsersService.loadAdministrators().$promise.then((response) => {
            this.users = response;
            if (typeof (callback) == 'function') {
                callback();
            } else {
                this.loadingTask = false;
            }
        }, () => {
            this.loadingTask = false;
        });
    };

    loadTargets = () => {
        if (this.task) {
            this.task.actions.forEach((action, actionKey) => {
                if (action.report_to && action.report_to.selectedData) {
                    this.TargetsService.getSelected(
                        action.report_to.selectedData, this,
                        (ctrl, response) => {
                            ctrl.task.actions[actionKey].report_to.targets = response;
                        });
                }
            });
            this.loadingTask = false;
        } else {
            this.loadingTask = false;
        }
        this.lastTask = angular.toJson(this.task);
    };

    removeTarget = (actionKey, item) => {
        if (item && item.id && this.task.actions &&
            this.task.actions[actionKey] &&
            this.task.actions[actionKey].report_to &&
            this.task.actions[actionKey].report_to.selectedData) {
            this.task.actions[actionKey].report_to.selectedData.unselectedTargets = { 0: [item.id] };
            this.task.actions[actionKey].loadingSelectedData = true;
            this.TargetsService.rebuildSelectedData(
                this.task.actions[actionKey].report_to.selectedData, this,
                (ctrl, response) => {
                    ctrl.task.actions[actionKey].report_to.selectedData = response;
                    delete ctrl.task.actions[actionKey].loadingSelectedData;
                }, (ctrl) => {
                    delete ctrl.task.actions[actionKey].loadingSelectedData;
                });
            this.isEdit = true;
        }
    };

    editTargets = (action, actionKey) => {
        const { $localStorage } = this;
        $localStorage.schedulerTaskData = {
            schedulerTask: this.task,
            selectedData: action && action.report_to
                ? action.report_to.selectedData
                : angular.copy(EMPTY_SELECTED_DATA),
            schedulerTaskId: this.taskId,
            schedulerActionKey: actionKey
        };
        window.appCtrl.go('targets');
        this.$close();
    };

    changeSelectedData = () => {
        const { $localStorage } = this;
        if (!this.task.selectedData) {
            this.task.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), { all: 1, selectedCount: { targets: +this.targetCount } });
        }
        $localStorage.schedulerTaskSelectedData = {
            schedulerTask: this.task,
            schedulerTaskId: this.taskId
        };
        window.appCtrl.go('targets');
        this.$close();
    };

    setReportToType = (action, reportToType) => {
        action.report_to.type = reportToType;
        if (reportToType == 3) {
            angular.forEach(this.reportTypes, (reportType) => {
                action[reportType.id] = 0;
            });
            action.report_education = 1;
        }
    };

    changeTriggerDayValue = (trigger) => {
        trigger.day_value = trigger.day_value * -1;
    };

    onSelectTriggerType = (triggerKey) => {
        let trigger = this.task.triggers[triggerKey];

        if (trigger && trigger.type) {
            this.clearErrors('triggerType');
            if (trigger.type.indexOf('course') > -1 && !this.hasValidCourses) {
                trigger.trigger_mode = 'many';
            }
            if (!trigger.trigger_mode && trigger.type.indexOf('course') > -1) {
                trigger.trigger_mode = 'any';
            }
        }
        this.task.triggers[triggerKey] = this.newTrigger(trigger);
        // если есть эксклюзтивные триггеры, то оставляем только их
        // не решая, сколько их может быть
        let exclusive = this.task.triggers.filter(function (trigger) {return trigger.isExclusive;});
        if (exclusive.length) {
            this.task.triggers = exclusive;
        }
        this.$scope.$apply();
        this.isEdit = true;
    };

    onSelectActionTemplate = (template) => {
        this.templateNameChosen = this.getActionTemplateName(template);
    }

    onSelectActionType = (actionKey) => {
        let action = this.task.actions[actionKey];
        if (action && action.type) {
            this.clearErrors('actionType');
            if (action.type == 'report_admin') {
                if (action.report_to && !action.report_to.type && (!action.report_to.administrators || !action.report_to.administrators.length)) {
                    angular.forEach(this.users, (user) => {
                        if (user.id == this.$localStorage.userInfo.id) {
                            action.report_to.administrators = [user];
                        }
                    });
                }
            }
            if (action.type.indexOf('course') > -1) {
                if (!this.hasValidCourses) {
                    action.action_mode = 'many';
                }
                action.action_period = this.learningConfig.timePeriod || defaultLearningDeadlineUnit;
                action.action_value = this.learningConfig.timeValue || defaultLearningDeadlineValue;
            }
        }
        this.task.actions[actionKey] = action;
        this.actionNameCoosen = this.getActionTypeName();
        this.$scope.$apply();
    };

    toggleSelectedDirection = () => {
        this.task.selectedDirection = this.task.selectedDirection == 'up' ? 'down' : 'up';
    };

    rebuildSelection = (reverse = false) => {
        if (!this.skipRebuild) {
            let ctrl = this;
            let scope = this.$scope;
            this.rebuildLoading = true;
            if (!this.task.selectedData) {
                this.task.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), { all: 1, selectedCount: { targets: +this.targetCount } });
            }
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.task.selectedData));
            rebuildSelectedData(selectedData, {
                scope: scope,
                setLoadingFunction: (loading) => {
                    ctrl.rebuildLoading = loading;
                },
                setSelectedDataFunction: (newSelectedData) => {
                    newSelectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData));
                    ctrl.task.selectedData = newSelectedData;
                    scope.$apply();
                },
                callbackFunction: () => {
                    scope.$apply();
                },
                reverse: reverse
            });
        }
    };

    reverseSelection = () => {
        this.rebuildSelection(true);
    };

    isNewEmployeeTriggerSelected = () => {
        let selected = false;
        if (this.task.triggers && this.task.triggers.length !== 0) {
            this.task.triggers.forEach(trigger => {
                if (trigger.type == 'new_target') {
                    selected = true;
                }
            });
        }
        return selected;
    };

    /**
     * Задача сиюминутная: выполнять без задержек
     * @returns {boolean}
     */
    isMomentaryTask = () => {
        return this.task.triggers && this.task.triggers.length === 1 && this.task.triggers[0].isTimed;
    };

    /**
     * Если задача не содержит "экклюзивного" триггера (т.е. триггер в задаче модет быть только один, например "время пришло"),
     * то в она может иметь другие триггеры.
     * @returns {boolean} может ли задача включать более одного григгера
     */
    taskCanHasAnyTriggers = () => {
        return typeof this.task.triggers.find(function (trigger) {return trigger.isExclusive;}) === 'undefined';
    };

    // timeZoneUnfocused = () => {
    //     this.timeZones.$promise.then((timeZones) => {
    //         let timeZone = timeZones.find((tz) => {
    //             return tz.name == this.userInfo.timeZone;
    //         });
    //         if (timeZone && timeZone.name) {
    //             this.userInfo.timeZone = timeZone.name;
    //             this.userInfo.timeZoneGmt = timeZone.gmt;
    //         } else {
    //             this.userInfo.timeZone = this.lastTimeZone;
    //             this.userInfo.timeZoneGmt = this.lastTimeZoneGmt;
    //         }
    //         this.errorText = null;
    //     });
    // };

    setTimeZone = (tz, trigger) => {
        this.dateOptions = {
            minDate: momentTimezone.tz(tz.name).format('YYYY-MM-DD HH:mm:ss'),
            maxDate: null
        };

        if (trigger) {
            trigger.setTimeZone(tz);
        }
    };
}

SchedulerTaskModalController.$inject = [
    SCHEDULER_TASKS_SERVICE,
    USERS_SERVICE,
    TARGETS_SERVICE,
    AUTH_SERVICE,
    '$localStorage',
    '$scope',
    'taskId',
    'gettextCatalog',
    'settingsCtrl',
    '$ngRedux',
    TIME_ZONES_SERVICE
];

export {
    SchedulerTaskModalController
};
